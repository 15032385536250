import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TRUSTED_DOMAINS } from 'assets/constants';

@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	private readonly allowedDomains = [...TRUSTED_DOMAINS];

	constructor(private router: Router) {}

	/**
	 * Navigates to a route, handling both external URLs and internal routes
	 * @param route Destination route (can be external URL or internal route)
	 * @param queryParams Optional query parameters
	 * @param defaultRoute Default route if no route is provided
	 */
	navigatePost(route?: string, queryParams: Record<string, any> = {}, defaultRoute: string = '/dispatch/open'): void {
		if (route?.startsWith('http')) {
			if (this.isValidExternalUrl(route)) {
				window.location.href = route;
			} else {
				console.error('Invalid external URL:', route);
				this.router.navigate([defaultRoute]);
			}
		} else {
			this.router.navigate([route || defaultRoute], {
				queryParams: queryParams,
			});
		}
	}

	/**
	 * Handles secure redirections with token
	 */
	handleSecureRedirect(redirectTo: string | null, token?: string): void {
		if (!redirectTo || !this.isValidExternalUrl(redirectTo)) {
			return;
		}

		try {
			const redirectUrl = new URL(redirectTo);
			if (token) {
				redirectUrl.searchParams.set('token', token);
			}
			window.location.href = redirectUrl.toString();
		} catch (error) {
			console.error('Error handling secure redirect:', error);
		}
	}

	/**
	 * Validates if a URL is external and secure
	 */
	private isValidExternalUrl(url: string): boolean {
		try {
			const urlObj = new URL(url);
			console.log(urlObj);
			console.log(this.allowedDomains);
			const isLocalhost = urlObj.hostname === 'localhost' || urlObj.hostname === '127.0.0.1';
			return (
				(urlObj.protocol === 'https:' || isLocalhost) &&
				this.allowedDomains.some((domain) => {
					return urlObj.hostname === domain || urlObj.hostname.endsWith(`.${domain}`);
				})
			);
		} catch {
			return false;
		}
	}
}
