// External imports
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { lastValueFrom, Observable } from 'rxjs';

// Global imports
import { AddOnType } from 'assets/models/interfaces/IServiceAddOn';
import { ResponseData } from 'assets/models/ResponseData';
import { IService, Notes, ServiceAddons, ServiceSubType } from 'assets/models/Service';
import { ServiceType } from 'assets/models/ServiceType';

// Local imports
import {
	FollowUpServiceId,
	Payment,
	WarrantyServiceId,
} from 'modules/service/screens/service-container/services/types';
import { AuthenticationService } from '../auth';
import { BaseService } from './base.service';
import {
	DispatchInformativeServicesPayload,
	GetServiceCancellationFeeResponse,
	PaginatedServicesResponse,
	SendClosingRequestReq,
	SendClosingRequestResponse,
} from './types';
import { NoteChatType } from 'assets/constants/enums';
import {
	IAthMovilCancelPaymentPayload,
	IAthMovilPayload,
	IAthMovilVerifyQuery,
	IConfirmAthMovilPayload,
} from 'assets/models/interfaces/IAthMovilPayload';
import { IAthMovilNewPaymentResponse, IAthMovilPayment } from 'assets/models/interfaces/IAthMovilResponse';
import { AthMovilTransactionStatus } from '../payments/types';
import { ICheckInReminderHistory, ICheckInReminderRequest } from 'assets/models/interfaces/ICheckInReminder';

@Injectable({
	providedIn: 'root',
})
export class ServiceService extends BaseService {
	constructor(protected http: HttpClient, protected auth: AuthenticationService) {
		super(http, auth);
	}

	public finish(data: any): Promise<ResponseData> {
		return this.post(`services/finish`, data);
	}

	public finishAndNew(data: any): Promise<ResponseData> {
		return this.post(`services/finishAndNew`, data);
	}

	public visitPayment(data: any): Promise<ResponseData> {
		return this.post(`services/visitPayment`, data);
	}

	public getServices(params: any): Promise<ResponseData> {
		return this.get(`api/v1/services`, params);
	}

	public getServicesCount(params: any): Promise<ResponseData> {
		return this.get(`api/v1/services/count`, params);
	}

	public getPaginatedServices(payload): Observable<PaginatedServicesResponse> {
		return this.getAsObservable<PaginatedServicesResponse>('api/v2/services', {
			...payload,
			sortOptions: payload.sortOptions ? encodeURIComponent(JSON.stringify(payload.sortOptions)) : undefined,
			scheduleStartDate: payload.scheduleStartDate?.toISOString(),
			scheduleEndDate: payload.scheduleEndDate?.toISOString(),
			createdStartDate: payload.createdStartDate?.toISOString(),
			createdEndDate: payload.createdEndDate?.toISOString(),
			finishedStartDate: payload.finishedStartDate?.toISOString(),
			finishedEndDate: payload.finishedEndDate?.toISOString(),
			checkInStartDate: payload.checkInStartDate?.toISOString(),
			checkInEndDate: payload.checkInEndDate?.toISOString(),
			projection: JSON.stringify(payload.projection),
		});
	}

	public getServiceCancellationFee(serviceId: string): Promise<GetServiceCancellationFeeResponse> {
		const observable = this.getAsObservable<PaginatedServicesResponse>(`api/v2/services/${serviceId}/cancellation-fee`);
		return lastValueFrom(observable);
	}

	public getContactActiveService(params: any): Promise<ResponseData> {
		return this.get('api/v1/services/contact/active-service', params);
	}

	public getServiceById(id: string): Promise<ResponseData> {
		return this.get(`services/${id}`);
	}

	public getDeclinedServices(body: any): Promise<ResponseData> {
		return this.post(`services/getDeclined`, body);
	}

	public getCancelledAndDeclinedServices(body: any): Promise<ResponseData> {
		return this.post(`services/getCancelledAndDeclined`, body);
	}

	public getByContactId(contactId: string): Promise<ResponseData<Array<IService>>> {
		return this.get(`services/getServicesForContact/${contactId}`);
	}

	public searchServices(body: any): Promise<ResponseData> {
		return this.post(`services/search`, body);
	}

	public editHold(data: any): Promise<ResponseData> {
		return this.post(`services/holdEdit`, data);
	}

	public getTypes(): Promise<ResponseData<ServiceType[]>> {
		return this.get(`services/types/all`);
	}

	public getTypesHome(): Promise<ResponseData> {
		return this.get(`services/types/home`);
	}

	public getOtherTypes(): Promise<ResponseData> {
		return this.get(`services/types/other`);
	}

	public getPrimaryTypes(): Promise<ResponseData> {
		return this.get(`services/types/primary`);
	}

	public cancelFinished(data: object): Promise<ResponseData> {
		return this.patch(`services/cancelFinished`, data);
	}

	public finishCancelled(data: object): Promise<ResponseData> {
		return this.patch(`services/finishCancelled`, data);
	}

	public dispatchNotCovered(data: object): Promise<ResponseData> {
		return this.post(`services/dispatchNotCovered`, data);
	}

	public dispatchInformativeServices(
		data: DispatchInformativeServicesPayload
	): Promise<ResponseData<{ serviceNumber: number }>> {
		return this.post(`services/informatives`, data);
	}

	public editSurplus(data: object): Promise<ResponseData> {
		return this.patch(`services/updateSurplus`, data);
	}

	public addContactAttempt(data: object): Promise<ResponseData> {
		return this.put(`services/contactattempts`, data);
	}

	public toggleSnooze(data: any): Promise<ResponseData> {
		return this.put(`services/snooze`, data);
	}

	public clearSchedule(serviceId: string): Promise<ResponseData> {
		return this.delete(`services/schedule/${serviceId}`);
	}

	public async toggleAjusteExpress(data): Promise<ResponseData> {
		return await this.patch(`services/ajusteExpress/toggle`, { ...data });
	}

	public async togglePrepaid(serviceId: string, isPrepaid: boolean): Promise<ResponseData> {
		return await this.patch(`services/${serviceId}/prepaid`, { isPrepaid });
	}

	public async toggleAuditHold(serviceId: string, isAuditHold: boolean): Promise<ResponseData> {
		return await this.patch(`services/${serviceId}/audit-hold`, { isAuditHold });
	}

	public async delayService(data): Promise<ResponseData> {
		return await this.patch(`services/monitor`, { ...data });
	}

	public async getNotes(serviceId: string, chatType: NoteChatType): Promise<Notes[]> {
		return await this.get(`dispatch/gNotes/${serviceId}`, { chatType });
	}

	public async markAsRead(serviceId: string, chatType?: string): Promise<ResponseData> {
		return await this.patch(`services/${serviceId}/notes`, { fromFlag: 'Helios', chatType });
	}

	public async hideNote(serviceId: string, noteId: string): Promise<ResponseData> {
		return await this.patch(`services/${serviceId}/notes/${noteId}`, {});
	}

	public editPayment(data: any, refundOldPayment: boolean = true): Promise<ResponseData> {
		return this.patch(`services/editPayment/?refundOldPayment=${refundOldPayment}`, data);
	}
	public uploadFiles(serviceId: string, data: any): Promise<ResponseData> {
		return this.post(`services/${serviceId}/files`, data);
	}
	public deleteFiles(serviceId: string, fileId: string): Promise<ResponseData> {
		return this.delete(`services/${serviceId}/files/${fileId}`);
	}
	public editServiceAddons(serviceId: string, data: ServiceAddons, type: AddOnType): Promise<ResponseData> {
		return this.patch(`services/${serviceId}/addons?type=${type}`, data);
	}
	public saveServiceSubType(serviceId: string, data: ServiceSubType, type: AddOnType): Promise<ResponseData> {
		return this.patch(`services/${serviceId}/subType?type=${type}`, data);
	}
	public confirmSubType(serviceId: string, data: ServiceSubType, type: AddOnType): Promise<ResponseData> {
		return this.put(`services/${serviceId}/subType/confirm?type=${type}`, data);
	}
	public editSubType(
		serviceId: string,
		subTypeId: string,
		data: ServiceSubType,
		type: AddOnType
	): Promise<ResponseData> {
		return this.patch(`services/${serviceId}/subType/${subTypeId}?type=${type}`, data);
	}
	/**
	 * Delete a service subtype
	 * @param {string} serviceId - The id of the service you want to delete the subtype from.
	 * @param {string} subTypeId - The id of the subtype you want to delete
	 * @param {AddOnType} type - AddOnType - this is an enum that can be found in the AddOnType.ts file.
	 * @returns Promise<ResponseData>
	 */
	public deleteServiceSubType(serviceId: string, subTypeId: string, type: AddOnType): Promise<ResponseData> {
		return this.delete(`services/${serviceId}/subType/${subTypeId}?type=${type}`);
	}

	async getDocumentDownload(url: string, name: string, type: string) {
		let headers = new HttpHeaders();
		if (type) headers = headers.set('Accept', type);
		try {
			const resp: any = await this.http
				.get(url, {
					headers,
					responseType: 'blob' as 'json',
				})
				.toPromise();
			const blob = new Blob([resp], {
				type: `${type};charset=utf-8`,
			});
			saveAs(blob, name);
			return true;
		} catch (error) {
			return false;
		}
	}

	public async createWarranty(
		serviceId: string,
		accountId: string,
		serviceTypeId: string
	): Promise<ResponseData<WarrantyServiceId>> {
		return this.post(`services/${serviceId}/warranties`, {
			accountId: accountId,
			serviceTypeId: serviceTypeId,
		});
	}

	public async createFollowUp(
		serviceId: string,
		accountId: string,
		serviceTypeId: string
	): Promise<ResponseData<FollowUpServiceId>> {
		return this.post(`services/${serviceId}/followUp`, {
			accountId: accountId,
			serviceTypeId: serviceTypeId,
		});
	}

	public async markSurveyAsReviewed(serviceId: string): Promise<ResponseData> {
		return await this.post(`surveys/reviewed`, { serviceId });
	}

	public async createNewPayment(payment: Payment, service: IService): Promise<ResponseData> {
		return this.post(`services/newPayment`, { payment, service });
	}

	public async payAsAthMovil(athMovilPayload: IAthMovilPayload): Promise<ResponseData<IAthMovilNewPaymentResponse>> {
		return await this.post(`services/payments/athmovil`, athMovilPayload);
	}

	public async verifyAthMovilPayment(
		queryParams: IAthMovilVerifyQuery
	): Promise<ResponseData<AthMovilTransactionStatus>> {
		return await this.get('services/payments/athmovil/verify', queryParams);
	}

	public confirmAthMovilPayment(athMovilPayload: IConfirmAthMovilPayload): Promise<ResponseData<IAthMovilPayment>> {
		return this.post(`services/payments/athmovil/confirm`, athMovilPayload);
	}

	public async cancelAthMovilPayment(athMovilCancelPayload: IAthMovilCancelPaymentPayload): Promise<ResponseData> {
		return this.post(`services/payments/athmovil/cancel`, athMovilCancelPayload);
	}

	public async getFollowUpList(id: string): Promise<ResponseData<{ followUps?: number[] }>> {
		return await this.get(`services/${id}/followups`);
	}

	/**
	 * Sends a check-in reminder payload to the specified API endpoint.
	 * @param {ICheckInReminderRequest} checkInReminder - An object containing the check-in reminder details.
	 * @returns {Promise<ResponseData>} A promise that resolves to the response data from the API.
	 */
	public async saveCheckInReminder(checkInReminder: ICheckInReminderRequest): Promise<ResponseData> {
		const payload = {
			serviceId: checkInReminder.serviceId,
			userId: checkInReminder.userId,
			checkInDate: checkInReminder.checkInDate,
			reason: checkInReminder.reason,
			branch: checkInReminder.branch,
			note: checkInReminder.note,
		};
		return await this.post('api/v1/services/checkInReminder', payload);
	}

	/**
	 * Retrieves the check-in reminder history for a specific service.
	 * @param {string} serviceId - The ID of the service for which the check-in reminder history is being requested.
	 * @returns {Promise<ResponseData<ICheckInReminderHistory[]>>} A promise that resolves to the response data containing the check-in reminder history.
	 */
	public async getCheckInReminderHistory(serviceId: string): Promise<ResponseData<ICheckInReminderHistory[]>> {
		return await this.get(`api/v1/services/checkInReminder/${serviceId}`);
	}

	public setWarrantyApplicability(
		serviceId: string,
		warrantyApplicabilityPayload: { warrantyApplies: boolean; warrantyNote: string }
	): Promise<ResponseData> {
		return this.patch(`services/${serviceId}/warranties`, warrantyApplicabilityPayload);
	}

	public async sendClosingRequest(
		serviceNumber: number,
		body: SendClosingRequestReq
	): Promise<SendClosingRequestResponse> {
		return await this.post(`api/v2/services/${serviceNumber}/qualitas-closure-request`, body);
	}
}
