import { Environments } from './types';

export const baseProdEnv: Environments = {
	production: true,
	ENV_NAME: 'production',
	athMovilEnv: 'production',
	apiLink: 'https://helios-api-prod.herokuapp.com',
	auto_dispatch_url: 'https://auto-dispatch.apps-connectassistance.com',
	commonApiLink: 'https://common-api.apps-connectassistance.com/api',
	customersApiUrl: 'https://api-customersetl-prod-pg.herokuapp.com',
	customersApiToken:
		'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiaGVsaW9zIn0.3-cdxNEY823SzghSYsSR3e7xV1MvBg_ows0QwB3iKs0',
	inspectionLink: 'https://connectasistencia.com/formulario/',
	managementClientLink: 'https://helios-management.herokuapp.com/#',
	salesApiLink: 'https://helios-sales-api.apps-connectassistance.com',
	salesClientLink: 'https://sales.connectasistencia.com/#/',
	stripeKey:
		'pk_live_5190uUtHDk9bOOMjAOpZVAFtuwQV1BwulCFhIJNFtLddpDFwZ7bXmnxtlmtUuoLCZ2Wxu8pJ18Xn30dFFaJc6sD7P00lukF5fmN',
	stripeKeyPR:
		'pk_live_5190uUtHDk9bOOMjAOpZVAFtuwQV1BwulCFhIJNFtLddpDFwZ7bXmnxtlmtUuoLCZ2Wxu8pJ18Xn30dFFaJc6sD7P00lukF5fmN',
	stripeKeyPRHome:
		'pk_live_51EfZvUBG3rK0Zer37smW4VmnoFsbq2ipdIoq6FJGYEPkR9Nh01LTbfWboUItP9mDIBs0SkOhWIC3A06c3Bjw7SHU00RPVnW7NS',
	stripeKeyPA:
		'pk_live_51CwGaHA0iubLCAyqcT8HerUcFmOJURSg3IT12Yf2WXiAXQslx7FCcieN3V6lrDJegTlccxXq7BXGi3MjMxtiddGl00xdYdJ5h7',
	stripeKeyMX:
		'pk_live_51CwGaHA0iubLCAyqcT8HerUcFmOJURSg3IT12Yf2WXiAXQslx7FCcieN3V6lrDJegTlccxXq7BXGi3MjMxtiddGl00xdYdJ5h7',
	stripeKeyCR: 'pk_live_xItLrKZN3W32vQ5vCUCTWk3v004t1RWiz6',
	connectServicesUrl: 'https://connectservicesapp.azurewebsites.net',
	eosUrl: 'https://eos-web.connect.pr',
	proMigrationServiceUrl: 'https://mu22w5821a.execute-api.us-east-1.amazonaws.com/prod',
	proMigrationServiceApiKey: 'ZZJ5POQd1B80oIcQI6dVv4clAaGk9CaP6THbEjkx',
	s3BucketPR: 'inspeccionespri',
	s3BucketCR: 'inspeccionescr',
	s3BucketPAN: 'inspeccionespty',
	s3BucketRegion1: 'us-east-1',
	s3BucketRegion2: 'us-east-2',
	ROAD_PUBLIC_TOKEN: 'ATRIPGS4Q76F1Z5M3ZREMA770FX8GP1IUIJYG5ZI',
	HOME_PUBLIC_TOKEN: '89673cdd857b9df23d1dc7b4ae131fc7b21a7d34',
	S3_MANAGER_SERVICE_URL: 'https://bhq0b2vz1i.execute-api.us-east-1.amazonaws.com/prod',
	S3_MANAGER_SERVICE_API_KEY: 'NGRut4WctyXmGuJrgaTt73Xiyxizq3T4g6Eqleng',
	ETL_CLIENTS_FILES_BUCKET_NAME: 'cs-mediafiles',
	ETL_CLIENTS_FILES_BUCKET_REGION: 'us-east-1',
	PROFILE_IMAGES_BUCKET_NAME: 'inspecciones',
	PROFILE_IMAGES_BUCKET_REGION: 'us-east-1',
	ACCOUNT_BRANDING_BUCKET_NAME: 'connect-assistant-public-assets',
	ACCOUNT_BRANDING_BUCKET_REGION: 'us-east-1',
	STORAGE_KEY: 'cLOteS$8ic@lOOX0ellaL7gWHaB8TY9E7$effHOFCB0jSSUQ&!V&P9SHGNAzL1Wpw^VBwHotOr4mlwPphqnMeUMUu#oNEKtGDmT',
	MAPBOX_TOKEN:
		'pk.eyJ1IjoidGVjaG5vbG9neS1leHBlbnNlcyIsImEiOiJjbGtpYmNydWYwMjNxM2hrNm1vcWFwemRxIn0.ZZZwX6ELsTMkfqKAHcms7w',
	FEATURE_FLAG__BranchMexico: true,
	SENTRY_ERROR_SAMPLING_RATE: 0.01,
	SENTRY_TRANSACTION_SAMPLING_RATE: 0,
	SERVICES_UPDATER_SERVICE_URL: 'https://kpvimgxrt0.execute-api.us-east-1.amazonaws.com/prod',
	SERVICES_UPDATER_SERVICE_API_KEY: 'wRiKA2FmRb1nhu2bYeARx748uZD3jf039KDO68M8',
	OSRM_BASE_URL: 'https://osrm.apps-connectassistance.com',
	GOOGLE_MAPS_KEY: 'AIzaSyCTifgP1ekGoF1hEJ7j1Z3Y2c5orapdNDM',
	COGNITO_CLIENT_ID: '7a3afjuftnmokcgskc9caehker',
	COGNITO_DOMAIN: 'https://helios-web.auth.us-east-1.amazoncognito.com',
	ETL_DEFINITIONS_BASE_URL: 'https://gpt.apps-connectassistance.com',
	ETL_DEFINITIONS_TOKEN: 'oasyMaRDueRMIleVALDROWsws',
	DLOCAL_CR_KEY: '6e5b04cb-c694-4194-92d9-78df3e12fc79',
	DLOCAL_CO_KEY: '6b4a4866-9a00-4d05-8dd7-884ea9fd2ed8',
	DLOCAL_URL: 'https://js.dlocal.com/',
	DISPATCH_QUOTER_IFRAME: 'https://dispatch.connect.pr',
	EXAMPLE_FORM_IFRAME_URL: 'https://dispatch.connect.pr',
	TRUSTED_DOMAINS: 'connect.pr,connectasistencia.com',
};
