import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TitleStrategy, RouterStateSnapshot } from '@angular/router';

/**
 * A custom title strategy for updating the document title dynamically based on the current route.
 * Sets a default title if no specific title is provided in the route configuration.
 */
@Injectable()
export class RouteParamTitleStrategy extends TitleStrategy {
	constructor(private readonly titleService: Title) {
		super();
	}

	/**
	 * Updates the document title using the title defined in the route configuration
	 * or falls back to the default title if none is provided.
	 * @param snapshot - The current router state snapshot containing route details.
	 */
	updateTitle(snapshot: RouterStateSnapshot): void {
		const newTitle = this.buildTitle(snapshot); // Build the title from the route config
		this.titleService.setTitle(newTitle || 'Helios - Connect Assistance'); // Apply the new or default title
	}
}
