// External imports
import { Injectable } from '@angular/core';
import {
	BehaviorSubject,
	Observable,
	catchError,
	filter,
	fromEvent,
	interval,
	map,
	switchMap,
	take,
	takeUntil,
} from 'rxjs';

// Global imports
import { User } from 'assets/models/User';
import { EncryptedLocalStorageService } from '../storage';
import { CognitoService } from './cognito';
import { ToastrService } from 'ngx-toastr';
import { NavigationService } from '../navigation';

// Local imports

@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	private logInStateSubject = new BehaviorSubject<boolean>(false);
	userLoggedInObservable = this.logInStateSubject.asObservable();
	private cognitoLoginUrl: string;
	private cognitoLogoutUrl: string;
	private refreshInterval: any;

	constructor(
		private storage: EncryptedLocalStorageService,
		private cognitoService: CognitoService,
		private toastr: ToastrService,
		private navigationService: NavigationService
	) {
		// Subject state initialization
		this.logInStateSubject.next(!this.isTokenExpired());
		// Subject emitter
		storage.observe('expires').subscribe(() => {
			this.logInStateSubject.next(!this.isTokenExpired());
		});
		this.cognitoLoginUrl = `${this.cognitoService.getCognitoDomain()}/oauth2/authorize?redirect_uri=${encodeURIComponent(
			this.cognitoService.getRedirectUri()
		)}&response_type=CODE&client_id=${this.cognitoService.getClientId()}&scope=openid%20profile%20email`;

		this.cognitoLogoutUrl = `${this.cognitoService.getCognitoDomain()}/logout?client_id=${this.cognitoService.getClientId()}&logout_uri=${this.cognitoService.getLogoutUri()}&redirect_uri=${this.cognitoService.getLogoutUri()}`;
	}

	storeAuth(token: string, expires: number, loginType: string, refreshToken?: string): void {
		try {
			this.storage.secureStore('token', token);
			this.storage.secureStore('refreshToken', refreshToken);
			this.storage.store('expires', expires);
			this.storage.store('loginType', loginType);

			const params = new URLSearchParams(window.location.search);
			const redirectTo = params.get('redirectTo');

			if (redirectTo) {
				this.navigationService.handleSecureRedirect(redirectTo, token);
			}
		} catch (error) {
			console.error('Error storing auth data:', error);
			throw new Error('Failed to store auth data');
		}
	}

	checkAuthAndRedirect(): void {
		const params = new URLSearchParams(window.location.search);
		const redirectTo = params.get('redirectTo');

		if (redirectTo) {
			const token = this.storage.secureRetrieve('token');
			const expires = this.storage.retrieve('expires');

			if (token && expires && new Date().getTime() < expires) {
				this.navigationService.handleSecureRedirect(redirectTo, token);
			} else {
				this.navigationService.navigatePost('/login', { redirectTo });
			}
		}
	}

	storeUser(user: User): void {
		this.storage.store('user', user);
	}

	getAuthToken(): string {
		return this.storage.secureRetrieve('token');
	}

	getRefreshToken(): string {
		return this.storage.secureRetrieve('refreshToken');
	}

	getUserId(): string {
		return this.storage.retrieve('_id');
	}

	isTokenExpired(): boolean {
		return Date.now() > this.storage.retrieve('expires');
	}

	isTokenAboutToExpire(): boolean {
		const expires = this.storage.retrieve('expires');
		const now = Date.now();

		const timeUntilExpiration = expires - now;
		const bufferTime = 3 * 60 * 1000;

		return timeUntilExpiration <= bufferTime;
	}

	signInWithGoogle(): Observable<any> {
		const width = 500;
		const height = 600;
		const left = window.screen.width / 2 - width / 2;
		const top = window.screen.height / 2 - height / 2;

		const popup = window.open(
			this.cognitoLoginUrl,
			'googleLogin',
			`width=${width},height=${height},top=${top},left=${left}`
		);

		if (!popup) {
			return new Observable<string>((observer) => observer.error('Popup blocked'));
		}

		return new Observable<string>((observer) => {
			const popupClosed$ = interval(500).pipe(
				filter(() => !popup || popup.closed),
				take(1)
			);

			const message$ = fromEvent<MessageEvent>(window, 'message').pipe(
				filter((event) => event.origin === window.location.origin && event.data?.type === 'google-auth'),
				map((event) => event.data.code),
				takeUntil(popupClosed$)
			);

			message$.subscribe({
				next: (code) => {
					if (code) {
						observer.next(code);
						observer.complete();
					} else {
						this.toastr.error('Failed to sign in with Google');
					}
				},
				error: (err) => observer.error(err),
			});

			return () => {
				popupClosed$.subscribe().unsubscribe();
				if (popup && !popup.closed) {
					popup.close();
				}
			};
		}).pipe(
			switchMap((code) => this.cognitoService.getTokens('authorization_code', code, undefined)),
			catchError((err) => {
				return new Observable<null>((observer) => observer.next(null));
			})
		);
	}

	logoutWithGoogle(): void {
		this.cognitoService.revokeAccess(this.getRefreshToken()).subscribe((response) => {
			window.location.href = this.cognitoLogoutUrl;
		});
	}

	refreshAuthToken(): void {
		this.cognitoService.getTokens('refresh_token', undefined, this.getRefreshToken()).subscribe((response) => {
			const expirationTime = Date.now() + response.expires_in * 1000;
			this.storeAuth(response.access_token, expirationTime, 'google', response.refresh_token);
		});
	}

	startTokenMonitor(): void {
		if (this.refreshInterval) {
			clearInterval(this.refreshInterval);
		}
		this.refreshInterval = setInterval(() => {
			if (this.isTokenAboutToExpire()) {
				this.refreshAuthToken();
			}
		}, 60 * 1000);
	}

	stopTokenMonitor(): void {
		if (this.refreshInterval) {
			clearInterval(this.refreshInterval);
		}
	}
}
